body {
  >iframe {
    display: none;
  }
}

// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";


.without-table-action {
  tbody {
    tr {
      td {
        padding: 0.4rem !important;
      }
    }
  }
}

.action-button {
  &.btn {
    padding: 1px 6px 1px 6px !important;
  }
}

.add-action-button {
  &.btn {
    padding: 3px 8px 3px 8px !important;
  }
}

.modal-close-button {
  button.close {
    color: white;

    &:focus {
      color: white;
    }

    &:hover {
      color: white;
    }
  }
}